import React from 'react'
import styled from 'styled-components'

import { TeacherFavoriteToggler } from '@/components/_shared/FavoriteToggler'
import { NextImageAvatar } from '@/components/_shared/NextImage'
import { NextLink } from '@/components/_shared/NextLink'
import { DisciplineListComma } from '@/components/discipline/DisciplineListComma'
import { ITeacherDecorated } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { buildInstructorRoute } from '@/utils/route'

interface IProps {
  teacher: ITeacherDecorated
  className?: string
}

export const TeacherListItem: React.FC<IProps> = ({ teacher, className }) => (
  <Wrapper className={className}>
    <ImageWrapper>
      <NextImageAvatar
        src={teacher.avatarPath}
        alt={teacher.name}
        sizes={`(max-width: ${DEVICE_WIDTH.MOBILE}px) 55vw, 15vw`}
      />
      {teacher.scope?.includes('video') && <VideoTag>vidéos</VideoTag>}
      <TeacherFavoriteToggler teacher={teacher} />
    </ImageWrapper>

    <StyledLink href={buildInstructorRoute(teacher)}>{teacher.fullName}</StyledLink>
    <StyledDisciplineListComma disciplineList={teacher.disciplinesOnly} />
  </Wrapper>
)

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
`
const ImageWrapper = styled.div`
  aspect-ratio: 1;
  position: relative;
  width: 80%;

  @supports not (aspect-ratio: 1) {
    padding-top: 80%;
  }
`
const StyledLink = styled(NextLink)`
  ${({ theme }) => theme.typo.h4}
  ${({ theme }) => theme.typo.bold}

  margin-top: ${({ theme }) => theme.spacing.s};
  text-align: center;
`
const StyledDisciplineListComma = styled(DisciplineListComma)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  line-clamp: 1;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
`
const VideoTag = styled.div`
  ${({ theme }) => theme.typo.mention}
  ${({ theme }) => theme.typo.bold}

  align-items: center;
  background-color: ${({ theme }) => theme.color.terracota};
  border-radius: ${({ theme }) => theme.spacing.l};
  bottom: -12px;
  color: ${({ theme }) => theme.color.white};
  display: flex;
  left: 0;
  line-height: 14px;
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
  position: absolute;
  right: 0;
  width: fit-content;

  &::before {
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: ${({ theme }) => theme.spacing.xs};
    margin-right: ${({ theme }) => theme.spacing.xs};
    width: ${({ theme }) => theme.spacing.xs};
  }
`
