import React from 'react'

import { CarouselMobileOnly } from '@/components/_shared/style'

import { PillarListItemSkeleton } from './PillarListItemSkeleton'

export const PillarListCarouselLoading: React.FC = () => (
  <CarouselMobileOnly>
    {[...Array(3)].map((x, i) => (
      <PillarListItemSkeleton key={i} />
    ))}
  </CarouselMobileOnly>
)
