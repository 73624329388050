import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { theme } from 'theme'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { DEVICE_WIDTH } from '@/utils/constants'

export const ElleAdvantageSlice: React.FC = () => (
  <AdvantageContainer>
    <FormattedMessage
      tagName={'div'}
      defaultMessage="Retrouvez tous vos avantages dans votre espace dédié"
      description="HomeOlybeElleStatementSlice: advantage label"
    />

    <ButtonLinkSubscriber
      href="/auth/signin?redirectTo=/abonnees-elle"
      color={theme.color.black}
    >
      <FormattedMessage
        defaultMessage="Abonnées ELLE"
        description="HomeOlybeElleStatementSlice: advantage link"
      />
    </ButtonLinkSubscriber>
  </AdvantageContainer>
)

const AdvantageContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.black};
  border-top: 1px solid ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.m};
  padding: ${({ theme }) => theme.spacing.m};

  div {
    ${({ theme }) => theme.typo.h3}
    text-align: center;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: center;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.l};
    justify-content: center;
    margin-bottom: 80px;
    margin-top: 80px;
  }
`
const ButtonLinkSubscriber = styled(CustomButtonLink)`
  ${({ theme }) => theme.typo.h4}
  ${({ theme }) => theme.typo.bold}
  align-self: center;
  border-radius: 28px;
  padding: ${({ theme }) => `12px ${theme.spacing.l}`};
  width: fit-content;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin: 0;
  }
`
