import { Url } from 'next/dist/shared/lib/router/router'
import React from 'react'
import styled from 'styled-components'

import { NextImage } from '@/components/_shared/NextImage'
import { LinkCover as LinkCoverBase } from '@/components/_shared/style'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  imgSrc: string
  imgAlt: string
  title: string
  description: string
  href?: Url
}

export const RoundImageAndDescription: React.FC<IProps> = ({
  imgSrc,
  imgAlt,
  title,
  description,
  href,
}) => (
  <Wrapper>
    {href ? <LinkCover href={href} /> : null}
    <ImageWrapper>
      <NextImage
        src={imgSrc}
        alt={imgAlt}
        imgixPreconfig="roundCropFace"
        sizes={`(max-width: ${DEVICE_WIDTH.MOBILE}px) 60vw, 33vw`}
        fill
      />
    </ImageWrapper>
    <Title>{title}</Title>
    <p>{description}</p>
  </Wrapper>
)

const Wrapper = styled.div`
  min-width: 75%;
  position: relative;
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex: 1;
    min-width: 0;
  }
`
const ImageWrapper = styled.div`
  aspect-ratio: 1;
  margin: auto;
  position: relative;
  width: 80%;

  @supports not (aspect-ratio: 1) {
    padding-top: 80%;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: 100%;
  }
`
const Title = styled.p`
  ${({ theme }) => theme.typo.h4}
  ${({ theme }) => theme.typo.bold}

  margin: ${({ theme }) => theme.spacing.s} 0;
`

const LinkCover = styled(LinkCoverBase)`
  z-index: 2;
`
