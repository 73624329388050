import React from 'react'
import styled from 'styled-components'

import { NextLink } from '@/components/_shared/NextLink'
import { IPropertyValueDiscipline } from '@/types/main'
import { buildDisciplineRoute } from '@/utils/route'

interface IProps {
  disciplineList: Partial<IPropertyValueDiscipline>[]
  enableLinks?: boolean
  className?: string
}

export const DisciplineListComma: React.FC<IProps> = ({
  disciplineList = [],
  enableLinks = false,
  className,
}) => {
  if (disciplineList.length === 0) {
    return null
  }

  if (!enableLinks) {
    const disciplineTitleList = disciplineList.map((d) => d.title).join(', ')

    return (
      <Wrapper className={className} title={disciplineTitleList}>
        {disciplineTitleList}
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className}>
      {disciplineList.map((discipline, index) => (
        <React.Fragment key={`discipline-${discipline.slug}`}>
          <NextLink href={buildDisciplineRoute(discipline)}>{discipline.title}</NextLink>
          {index !== disciplineList.length - 1 && ', '}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: ${({ theme }) => theme.color.grey};
`
