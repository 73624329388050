import { LinkProps } from 'next/link'
import React from 'react'
import { useIntl } from 'react-intl'

import { ContainerError } from '@/components/_layout/ContainerMessage'
import { RoundImageAndDescription } from '@/components/_shared/RoundImageAndDescription'
import { CarouselMobileOnly } from '@/components/_shared/style'
import { usePropertyValueSharedResults } from '@/hooks/use-property'
import { IPropertyValueDiscipline } from '@/types/main'
import { buildDisciplineRoute } from '@/utils/route'

import { PillarListCarouselLoading } from './loaders/PillarListCarouselLoading'

type PillarCarrouselReplacement = {
  [key: number]: { title: string; short_description: string; href: LinkProps['href'] }
}

interface IProps {
  replacement?: PillarCarrouselReplacement
}

export const PillarListCarousel: React.FC<IProps> = ({ replacement }) => {
  const intl = useIntl()
  const {
    propValueListLoading: pillarListLoading,
    propValueList: pillarList,
    propValueListError: pillarListError,
  } = usePropertyValueSharedResults('discipline')

  if (pillarListLoading) return <PillarListCarouselLoading />
  if (pillarListError) return <ContainerError />

  const pillarListWithReplacement = pillarList.map((pillar: IPropertyValueDiscipline) => {
    if (Object.keys(replacement).includes(`${pillar._id}`)) {
      pillar.title = replacement[pillar._id].title
      pillar.misc.short_description = replacement[pillar._id].short_description
    }

    return pillar
  })

  return (
    <CarouselMobileOnly>
      {pillarListWithReplacement.map((pillar: IPropertyValueDiscipline) => (
        <RoundImageAndDescription
          imgSrc={pillar.misc.artwork_path}
          imgAlt={intl.formatMessage(
            {
              defaultMessage: 'cours de {discipline} OLY Be',
              description: 'PillarListCarousel > {discipline}: image alt',
            },
            { discipline: pillar.title }
          )}
          title={pillar.title}
          description={pillar.misc.short_description}
          key={pillar._id}
          href={
            Object.keys(replacement).includes(`${pillar._id}`)
              ? replacement[pillar._id].href
              : buildDisciplineRoute(pillar)
          }
        />
      ))}
    </CarouselMobileOnly>
  )
}
