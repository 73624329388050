import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

const WIDTH = 340
const CIRCLE_MARGIN_BOTTOM = 24
const NAME_HEIGHT = 22
const NAME_MARGIN_BOTTOM = 8
const DESCRIPTION_HEIGHT = 44

export const PillarListItemSkeleton: React.FC = () => (
  <StyledContentLoader $WIDTH={WIDTH} uniqueKey="PillarListItemSkeleton">
    <circle cx={WIDTH / 2} cy={WIDTH / 2} r={WIDTH / 2} />
    <rect
      x={'0'}
      y={WIDTH + CIRCLE_MARGIN_BOTTOM}
      rx={NAME_HEIGHT / 2}
      ry={NAME_HEIGHT / 2}
      width={WIDTH}
      height={NAME_HEIGHT}
    />
    <rect
      x={'0'}
      y={WIDTH + CIRCLE_MARGIN_BOTTOM + NAME_HEIGHT + NAME_MARGIN_BOTTOM}
      rx={DESCRIPTION_HEIGHT / 2}
      ry={DESCRIPTION_HEIGHT / 2}
      width={WIDTH}
      height={DESCRIPTION_HEIGHT}
    />
  </StyledContentLoader>
)

const StyledContentLoader = styled(ContentLoader)<{ $WIDTH: number }>`
  height: ${({ $WIDTH }) =>
    $WIDTH +
    CIRCLE_MARGIN_BOTTOM +
    NAME_HEIGHT +
    NAME_MARGIN_BOTTOM +
    DESCRIPTION_HEIGHT}px;
  width: 100%;
`
