import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

interface IProps {
  width: number
}

const TEACHER_MARGIN_BOTTOM = 24
const NAME_HEIGHT = 22
const NAME_MARGIN_BOTTOM = 8
const DISCIPLINE_HEIGHT = 18

export const TeacherListItemSkeleton: React.FC<IProps> = ({ width }) => (
  <StyledContentLoader $width={width} uniqueKey="TeacherListItemSkeleton">
    <circle cx={width / 2} cy={width / 2} r={width / 2} />
    <rect
      x={'0'}
      y={width + TEACHER_MARGIN_BOTTOM}
      rx={NAME_HEIGHT / 2}
      ry={NAME_HEIGHT / 2}
      width={width}
      height={NAME_HEIGHT}
    />
    <rect
      x={'0'}
      y={width + TEACHER_MARGIN_BOTTOM + NAME_HEIGHT + NAME_MARGIN_BOTTOM}
      rx={DISCIPLINE_HEIGHT / 2}
      ry={DISCIPLINE_HEIGHT / 2}
      width={width}
      height={DISCIPLINE_HEIGHT}
    />
  </StyledContentLoader>
)

const StyledContentLoader = styled(ContentLoader)<{ $width: number }>`
  height: ${({ $width }) =>
    $width +
    TEACHER_MARGIN_BOTTOM +
    NAME_HEIGHT +
    NAME_MARGIN_BOTTOM +
    DISCIPLINE_HEIGHT}px;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  width: 100%;
`
