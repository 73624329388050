import React from 'react'
import { useIntl } from 'react-intl'

import { Container } from '@/components/_layout/Container'
import { GenericSlice } from '@/components/_layout/GenericSlice'
import { FeedbacksCarousel } from '@/components/_shared/FeedbacksCarousel'
import { IFeedback } from '@/utils/feedbacks'

interface IProps {
  feedbacks: IFeedback[]
  title?: string
  sectionTitle?: string
}

export const SurveyFeedbackListCarouselSlice: React.FC<IProps> = ({
  feedbacks,
  title,
  sectionTitle,
}) => {
  const intl = useIntl()

  if (!feedbacks) return null

  return (
    <GenericSlice
      title={
        title ??
        intl.formatMessage({
          defaultMessage: 'Paroles de Yogis',
          description: 'SurveyFeedbackListCarouselSlice: title',
        })
      }
      sectionTitle={
        sectionTitle ??
        intl.formatMessage({
          defaultMessage: 'Nos élèves témoignent',
          description: 'SurveyFeedbackListCarouselSlice: section title',
        })
      }
    >
      <Container>
        <FeedbacksCarousel feedbacks={feedbacks} />
      </Container>
    </GenericSlice>
  )
}
