import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { Container } from '@/components/_layout/Container'
import { GenericSlice } from '@/components/_layout/GenericSlice'
import { TeacherListCarousel } from '@/components/teacher/TeacherListCarousel'
import { ITeacherResultsVariables } from '@/services/api-graphql-teacher'
import { routes } from '@/utils/route'

interface IProps {
  gqlQuery: ITeacherResultsVariables['gqlQuery']
}

export const TeacherListCarouselSlice: React.FC<IProps> = ({ gqlQuery }) => {
  const intl = useIntl()

  return (
    <GenericSlice
      title={intl.formatMessage({
        defaultMessage: 'Rencontrez nos professeurs',
        description: 'TeacherListCarouselSlice: title',
      })}
      sectionTitle={intl.formatMessage({
        defaultMessage: 'La team olybe',
        description: 'TeacherListCarouselSlice: section title',
      })}
    >
      <TeacherListCarousel gqlQuery={gqlQuery} />

      <Container>
        <StyledButtonLink href={routes.explore.instructors} outlined>
          <FormattedMessage
            defaultMessage="Découvrir tous les professeurs"
            description="TeacherListCarousel: link"
          />
        </StyledButtonLink>
      </Container>
    </GenericSlice>
  )
}

const StyledButtonLink = styled(CustomButtonLink)`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.spacing.m};
  width: fit-content;
`
