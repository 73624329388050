import { KeyedMutator, SWRConfiguration } from 'swr'

import {
  getTeacherResults,
  ITeacherDecoratedGQLResults,
  ITeacherGQLResults,
  ITeacherResultsVariables,
} from '@/services/api-graphql-teacher'
import { HTTPError } from '@/utils/api-helpers'
import { decorateTeacher } from '@/utils/decorator'
import { buildGenericSwrMutate } from '@/utils/swr'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__TEACHER_RESULTS = 'teacher_results'

interface IReturnType {
  teacherListLoading: boolean
  teacherList: ITeacherDecoratedGQLResults
  teacherListError: HTTPError
  teacherListValidating: boolean
  mutateTeacherList: KeyedMutator<ITeacherGQLResults>
}

export const useSWRTeacherResults = (
  gqlVariables: ITeacherResultsVariables = {},
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): IReturnType => {
  const { payload } = useSWRAuth()
  const { data, error, mutate, isLoading, isValidating } =
    useSWRCustom<ITeacherGQLResults>(
      [SWR_KEY__TEACHER_RESULTS, payload?.uuid],
      getTeacherResults,
      gqlVariables,
      swrConfig
    )

  return {
    teacherListLoading: isLoading,
    teacherList: data && decorateTeacherList(data, mutate),
    teacherListError: error,
    teacherListValidating: isValidating,
    mutateTeacherList: mutate,
  }
}

const decorateTeacherList = (
  teacherResults: ITeacherGQLResults,
  mutateTeacherList: IReturnType['mutateTeacherList']
): ITeacherDecoratedGQLResults => {
  // Custom teacher.mutate that proxy change on mutateTeacherList
  const mutate = buildGenericSwrMutate(mutateTeacherList)

  const teacherDecoratedCollection = teacherResults.collection.map((teacher) =>
    decorateTeacher(teacher, mutate)
  )

  return {
    ...teacherResults,
    collection: teacherDecoratedCollection,
  }
}
