import React from 'react'
import styled from 'styled-components'

import { Carousel } from '@/components/_shared/Carousel'

import { TeacherListItemSkeleton } from './TeacherListItemSkeleton'

interface IProps {
  nbVisibleItems?: number
}

export const TeacherListCarouselLoading: React.FC<IProps> = ({ nbVisibleItems = 7 }) => (
  <Carousel nbVisibleItems={nbVisibleItems}>
    {[...Array(nbVisibleItems)].map((x, i) => (
      <ItemWrapper key={i}>
        <TeacherListItemSkeleton width={154} />
      </ItemWrapper>
    ))}
  </Carousel>
)

const ItemWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.s};
`
