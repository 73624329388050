import React from 'react'
import styled from 'styled-components'

import { ContainerError } from '@/components/_layout/ContainerMessage'
import { Carousel } from '@/components/_shared/Carousel'
import { useSWRTeacherResults } from '@/hooks/swr/useSwr-teacherResults'
import { ITeacherResultsVariables } from '@/services/api-graphql-teacher'
import { IAreaKey, IPropertyValueArea } from '@/types/main'

import { TeacherListCarouselLoading } from './loaders/TeacherListCarouselLoading'
import { TeacherListItem } from './TeacherListItem'

interface IProps {
  gqlQuery?: ITeacherResultsVariables['gqlQuery']
  itemsPerPage?: number
  area?: IPropertyValueArea
}

export const TeacherListCarousel: React.FC<IProps> = ({
  gqlQuery = 'Random',
  itemsPerPage = 24,
  area,
}) => {
  const { teacherListLoading, teacherList, teacherListError } = useSWRTeacherResults({
    gqlQuery,
    itemsPerPage,
    ...(area && { areaKey: area.slug as IAreaKey }),
  })

  if (teacherListLoading && !teacherList) return <TeacherListCarouselLoading />
  if (teacherListError) return <ContainerError />

  return (
    <CarouselWrapper>
      <Carousel nbVisibleItems={7}>
        {teacherList.collection.map((teacher) => (
          <TeacherListItem teacher={teacher} key={teacher._id} />
        ))}
      </Carousel>
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`
