import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { GenericSlice } from '@/components/_layout/GenericSlice'
import { PillarListCarousel } from '@/components/discipline/PillarListCarousel'
import { routes } from '@/utils/route'

export const PillarListCarouselSlice: React.FC = () => {
  const intl = useIntl()

  return (
    <GenericSlice
      title={intl.formatMessage({
        defaultMessage: 'Quelle pratique est faite pour vous ?',
        description: 'PillarListCarouselSlice: title',
      })}
    >
      <PillarListCarousel
        replacement={{
          188: {
            title: 'Pilates',
            short_description:
              'Pilates, cardio ou renforcement : bougez votre corps dans la bonne humeur pour vous vider la tête.',
            href: {
              pathname: routes.explore.discipline,
              query: {
                disciplineSlugs: ['fitness', 'renforcement', 'pilates'],
              },
            },
          },
        }}
      />

      <StyledButtonLink outlined href={routes.explore.disciplines}>
        <FormattedMessage
          defaultMessage="Découvrir les pratiques"
          description="PillarListCarouselSlice: button"
        />
      </StyledButtonLink>
    </GenericSlice>
  )
}

const StyledButtonLink = styled(CustomButtonLink)`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.spacing.m};
  width: fit-content;
`
